import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography, Space, Card } from 'antd';
import axios from 'axios';
import surveyrLogo from '../../assets/images/surveyr-logo-icon.png';
import facebookLogo from '../../assets/images/facebook-logo.png';
import whatsappLogo from '../../assets/images/whatsapp-logo.png';
import { useLocation } from 'react-router-dom';
import StatusNotification from '../StatusNotification';

const { Title, Paragraph } = Typography;

function WhatsApp() {
  const appId = '1240293813389635';
  const config_id = '1207010650539581';
  const [isConnecting, setIsConnecting] = useState(false);
  const location = useLocation();
  let { organizationId } = useSelector((state) => state.user);
  const { integrations } = useSelector((state) => state.integrations);

  if (organizationId === 'UwtbDE') {
    organizationId = 'surveyr';
  }

  const whatsappIntegration = integrations.find(
    (integration) => integration.id === 'whatsapp'
  );

  const isConnected = whatsappIntegration ? whatsappIntegration.connected : false;

  useEffect(() => {
    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // Initialize Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      });
    };

    // Check for success or error in URL params
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const error = searchParams.get('error');

    if (success === 'true') {
      StatusNotification(
        'success',
        'Integration Successful',
        'WhatsApp integration was successful!'
      );
    } else if (error) {
      StatusNotification(
        'error',
        'Integration Failed',
        'Failed to integrate with WhatsApp. Please try again.'
      );
    }
  }, [location]);

  const handleConnect = () => {
    setIsConnecting(true);
    // Conversion tracking code
    window.fbq &&
      window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
        appId: appId,
        feature: 'whatsapp_embedded_signup',
      });

    // Launch Facebook login
    window.FB.login(
      function (response) {
        console.log('Facebook login response:', response);
        if (response.authResponse) {
          console.log('Login successful.');
          handleAuthResponse(response.authResponse);
        } else {
          console.log('User cancelled login or did not fully authorize.');
          setIsConnecting(false);
        }
      },
      {
        config_id: config_id,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
        //  featureType: 'only_waba_sharing', // Bypass phone number selection
          setup: {
            // Prefilled data can go here
            // https://developers.facebook.com/docs/whatsapp/embedded-signup/pre-filled-data
          },
        },
      }
    );
  };

  const handleAuthResponse = (authResponse) => {
    console.log('authResponse:', authResponse);
    //TODO: use privateAxios
    axios
      .post('/api/facebook-webhook', {authResponse, organizationId})
      .then((response) => {
        console.log('Backend processed auth response:', response.data);
        setIsConnecting(false);
        // Handle the response from your backend
      })
      .catch((error) => {
        console.error('Error sending auth response to backend:', error);
        setIsConnecting(false);
        // Handle the error (e.g., show an error message to the user)
      });
  };

  const handleDisconnect = async () => {
    try {
      await axios.post('/api/whatsapp/disconnect', { organizationId });
      StatusNotification('success', 'Account Disconnected', 'Your WhatsApp account has been disconnected.');
      // You might want to update the local state or trigger a re-fetch of integrations here
    } catch (error) {
      console.error('Error disconnecting WhatsApp account:', error);
      StatusNotification('error', 'Disconnection Failed', 'Failed to disconnect your WhatsApp account. Please try again.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 64px)', // Adjust this value based on your header height
        padding: '20px',
      }}
    >
      <Card className='whatsapp-integration' style={{ width: '50%', maxWidth: '500px' }}>
        <Space
          direction='vertical'
          size='large'
          style={{ width: '100%', display: 'flex', alignItems: 'center' }}
        >
          <Title level={2}>WhatsApp Integration</Title>

          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}
          >
            <img src={surveyrLogo} alt='Surveyr Logo' style={{ width: '50px', height: '50px' }} />
            <img src={whatsappLogo} alt='WhatsApp Logo' style={{ width: '50px', height: '50px' }} />
          </div>

          <Paragraph style={{ textAlign: 'center' }}>
            {isConnected
              ? 'Your WhatsApp account is connected. You can now use WhatsApp for your surveys or disconnect your account.'
              : 'Connect directly with WhatsApp. Easily send surveys and collect responses via messaging.'}
          </Paragraph>

          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {!isConnected ? (
              <Button
                onClick={handleConnect}
                style={{
                  backgroundColor: '#1877F2',
                  borderColor: '#1877F2',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0 16px',
                  height: '40px',
                  borderRadius: '6px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
                disabled={isConnecting}
              >
                <img
                  src={facebookLogo}
                  alt='Facebook Logo'
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
                {isConnecting ? 'Connecting...' : 'Continue with Facebook'}
              </Button>
            ) : (
              <Button 
                type='primary' 
                danger 
                onClick={handleDisconnect}
              >
                Disconnect your account
              </Button>
            )}
          </div>
        </Space>
      </Card>
    </div>
  );
}

export default WhatsApp;
