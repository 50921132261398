export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_UP_USER = 'SIGN_UP_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHANGE_PLAN_TYPE = 'CHANGE_PLAN_TYPE';
export const REFRESH_SUBSCRIPTION = 'REFRESH_SUBSCRIPTION';
export const SET_COUNTERS = 'SET_COUNTERS';
export const SET_USER_MARKETING_EMAIL_STATUS = 'SET_USER_MARKETING_EMAIL_STATUS';
export const CANCEL_ACCOUNT_DELETION = 'CANCEL_ACCOUNT_DELETION';
export const INITIATE_ACCOUNT_DELETION = 'INITIATE_ACCOUNT_DELETION';

export const RESEND_CONFIRMATION_EMAIL = 'RESEND_CONFIRMATION_EMAIL';
export const VERIFY_CONFIRMATION_EMAIL = 'VERIFY_CONFIRMATION_EMAIL';

export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const FETCH_SURVEYS = 'FETCH_SURVEYS';
export const ADD_SURVEY = 'ADD_SURVEY';
export const CREATE_SURVEY_CLEAR = 'CREATE_SURVEY_CLEAR';
export const CREATE_SURVEY_INPUT_DATA = 'CREATE_SURVEY_INPUT_DATA';
export const CREATE_SURVEY_NUMBER_OF_QUESTIONS = 'CREATE_SURVEY_NUMBER_OF_QUESTIONS';
export const CREATE_SURVEY_SET_FORM_TYPE = 'CREATE_SURVEY_SET_FORM_TYPE';
export const CREATE_SURVEY_NAME = 'CREATE_SURVEY_NAME';
export const CREATE_SURVEY_COMPLETED = 'CREATE_SURVEY_COMPLETED';
export const CREATE_SURVEY_START_DATE = 'CREATE_SURVEY_START_DATE';
export const CREATE_SURVEY_END_DATE = 'CREATE_SURVEY_END_DATE';
export const CREATE_SURVEY_OPEN = 'CREATE_SURVEY_OPEN';
export const CREATE_SURVEY_SET_EMOJIS = 'CREATE_SURVEY_SET_EMOJIS';
export const CREATE_SURVEY_SET_CORRECT_ANSWER_OPTIONS = 'CREATE_SURVEY_SET_CORRECT_ANSWER_OPTIONS';
export const CREATE_SURVEY_SET_OPEN_ENDEDS = 'CREATE_SURVEY_SET_OPEN_ENDEDS';
export const REMOVE_SURVEY = 'REMOVE_SURVEY';
export const PROCESS_SURVEY = 'PROCESS_SURVEY';
export const RECEIVE_AI_GENERATED_SURVEY = 'RECEIVE_AI_GENERATED_SURVEY';
export const ACTIVATE_STATUS = 'ACTIVATE_STATUS';
export const SET_ACTIVE_SURVEY = 'SET_ACTIVE_SURVEY';
export const SET_IS_LIVE_EVENT = 'SET_IS_LIVE_EVENT';
export const SET_IS_LIVE_EVENT_IN_PROGRESS = 'SET_IS_LIVE_EVENT_IN_PROGRESS';
export const SET_IS_RANKING_EVENT_IN_PROGRESS = 'SET_IS_RANKING_EVENT_IN_PROGRESS';
export const SET_IS_QUIZ_IN_PROGRESS = 'SET_IS_QUIZ_IN_PROGRESS';
export const CREATE_SURVEY_SET_MARKETING_OPT_IN_MESSAGE =
  'CREATE_SURVEY_SET_MARKETING_OPT_IN_MESSAGE';
export const CREATE_SURVEY_SET_IS_OPT_IN = 'CREATE_SURVEY_SET_IS_OPT_IN';
export const CREATE_SURVEY_SET_IS_WELCOME_MESSAGE = 'CREATE_SURVEY_SET_IS_WELCOME_MESSAGE';
export const CREATE_SURVEY_SET_IS_CLOSING_MESSAGE = 'CREATE_SURVEY_SET_IS_CLOSING_MESSAGE';
export const CREATE_SURVEY_SET_WELCOME_MESSAGE = 'CREATE_SURVEY_SET_WELCOME_MESSAGE';
export const CREATE_SURVEY_SET_CLOSING_MESSAGE = 'CREATE_SURVEY_SET_CLOSING_MESSAGE';
export const SET_OPTION_MEDIA_URL = 'SET_OPTION_MEDIA_URL';
export const CREATE_SURVEY_SET_TESTIMONIAL_MESSAGE = 'CREATE_SURVEY_SET_TESTIMONIAL_MESSAGE';
export const CREATE_SURVEY_SET_IS_TESTIMONIAL = 'CREATE_SURVEY_SET_IS_TESTIMONIAL';
export const CREATE_SURVEY_WELCOME_MEDIA = 'CREATE_SURVEY_WELCOME_MEDIA';
export const CREATE_SURVEY_CLOSE_MEDIA = 'CREATE_SURVEY_CLOSE_MEDIA';
export const CREATE_SURVEY_CLOSE_MEDIA_MESSAGE = 'CREATE_SURVEY_CLOSE_MEDIA_MESSAGE';
export const CREATE_SURVEY_SET_IS_REPEATABLE = 'CREATE_SURVEY_SET_IS_REPEATABLE';
export const CREATE_SURVEY_SET_RESPONSE_SIZE = 'CREATE_SURVEY_SET_RESPONSE_SIZE';
export const CREATE_SURVEY_SET_FORM_BRICKS_URL = 'CREATE_SURVEY_SET_FORM_BRICKS_URL';

export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const RESET_TEMPLATE = 'RESET_TEMPLATE';
export const ADD_NEW_TEMPLATE = 'ADD_NEW_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SET_SHORT_URL_ID = 'SET_SHORT_URL_ID';
export const SET_WHATSAPP_MEDIA_METADATA = 'SET_WHATSAPP_MEDIA_METADATA';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_SELECTED_AND_TAGS = 'SET_SELECTED_AND_TAGS';
export const SET_SELECTED_NOT_TAGS = 'SET_SELECTED_NOT_TAGS';
export const SET_TEMPLATE_INPUT_VALUES = 'SET_TEMPLATE_INPUT_VALUES';
export const SET_TEMPLATE_MEDIA_URL = 'SET_TEMPLATE_MEDIA_URL';
export const CREATE_TEMPLATE_INPUT_DATA = 'CREATE_TEMPLATE_INPUT_DATA';
export const REMOVE_TEMPLATE_INPUT_VALUE = 'REMOVE_TEMPLATE_INPUT_VALUE';
export const RESET_TEMPLATE_FORM = 'RESET_TEMPLATE_FORM';

// Integrations
export const FETCH_INTEGRATIONS = 'FETCH_INTEGRATIONS';
export const CREATE_INTEGRATION = 'CREATE_INTEGRATION';
export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const SET_SELECTED_INTEGRATION = 'SET_SELECTED_INTEGRATION';
export const CLEAR_SELECTED_INTEGRATION = 'CLEAR_SELECTED_INTEGRATION';

// Knowledgebase
export const UPDATE_KNOWLEDGEBASE = 'UPDATE_KNOWLEDGEBASE';
export const SET_KNOWLEDGEBASE_FILES = 'SET_KNOWLEDGEBASE_FILES';
export const SET_KNOWLEDGEBASE_STATUS = 'SET_KNOWLEDGEBASE_STATUS';
export const SET_SELECTED_ASSISTANT_TYPE = 'SET_SELECTED_ASSISTANT_TYPE';
export const SET_KNOWLEDGEBASE_INSTRUCTIONS = 'SET_KNOWLEDGEBASE_INSTRUCTIONS';
export const SET_KNOWLEDGEBASE_URLS = 'SET_KNOWLEDGEBASE_URLS';

export const SET_SELECTED_PDFME_TEMPLATE = 'SET_SELECTED_PDFME_TEMPLATE';
export const SET_THREAD_ID = 'SET_THREAD_ID';
export const UPDATE_CONTENT_GROUPS = 'UPDATE_CONTENT_GROUPS';

export const SET_EXCHANGE_RATE = 'SET_EXCHANGE_RATE';

export const FETCH_BROADCASTS = 'FETCH_BROADCASTS';
export const CREATE_BROADCAST = 'ADD_BROADCAST';
export const DELETE_BROADCAST = 'REMOVE_BROADCAST';
export const CREATE_BROADCAST_CLEAR = 'CREATE_BROADCAST_CLEAR';
export const CREATE_BROADCAST_INPUT_DATA = 'CREATE_BROADCAST_INPUT_DATA';
export const CREATE_BROADCAST_ID = 'CREATE_BROADCAST_ID';
export const SET_SELECTED_BROADCAST = 'SET_SELECTED_BROADCAST';
export const SET_SELECTED_BROADCAST_SHORT_STATS = 'SET_SELECTED_BROADCAST_SHORT_STATS';
export const SET_LAST_SEEN = 'SET_LAST_SEEN';
export const CLEAR_LAST_SEEN = 'CLEAR_LAST_SEEN';
export const FETCH_BATCH_SIZE = 'FETCH_BATCH_SIZE';
export const FETCH_TAGS = 'FETCH_TAGS';
export const SET_SELECTED_TAGS = 'SET_SELECTED_TAGS';
export const RESET_TAGS = 'RESET_TAGS';
export const CLEAR_SELECTED_BROADCAST = 'CLEAR_SELECTED_BROADCAST';
export const FETCH_BROADCASTS_RESPONDENTS = 'FETCH_BROADCASTS_RESPONDENTS';

export const FETCH_CONTACTS = 'FETCH_CONTACTS';

export const LOAD_DASHBOARD_VALUES = 'LOAD_DASHBOARD_VALUES';
export const LOAD_CHARTS_DATA = 'LOAD_CHARTS_DATA';
export const DASHBOARD_SET_SEARCHED_TAGS = 'DASHBOARD_SET_SEARCHED_TAGS';
export const DASHBOARD_SET_ALL_TAGS = 'DASHBOARD_SET_ALL_TAGS';

export const LOAD_THIRTY_DAYS_CHART_DATA = 'LOAD_THIRTY_DAYS_CHART_DATA';
export const REMOVE_THIRTY_DAYS_CHART_DATA = 'REMOVE_THIRTY_DAYS_CHART_DATA';
export const LOCAL_DOWNLOAD_CHARTS_ARRAY = 'LOCAL_DOWNLOAD_CHARTS_ARRAY';

export const START_TOUR = 'START_TOUR';
export const NEXT_TOUR_STEP = 'NEXT_TOUR_STEP';
export const PREV_TOUR_STEP = 'PREV_TOUR_STEP';
export const END_TOUR = 'END_TOUR';
export const SET_TOUR_STEP = 'SET_TOUR_STEP';

export const IS_LOADING = 'IS_LOADING';

export const NOTIFICATION = 'NOTIFICATION';

export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';
