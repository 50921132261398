function isUrlValid(url) {
  console.log({ url });
  try {
    new URL(url.trim()); // Trim any whitespace
    return true;
  } catch (error) {
    return false;
  }
}

export default isUrlValid;
