import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';
import { Database } from 'lucide-react';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import { FacebookFilled, WhatsAppOutlined, FileExcelOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const integrationDefaults = [
  {
    icon: WhatsAppOutlined,
    title: 'WhatsApp',
    description: 'Enable your WhatsApp chatbot, connect your WhatsApp Business Account',
    id: 'whatsapp',
  },
  {
    icon: Database,
    title: 'Custom Knowledge',
    description:
      'Enhance your chatbot with tailored information from your own website, documents, or database.',
    id: 'knowledgebase',
  },
  {
    icon: FileExcelOutlined,
    title: 'Google Sheets',
    description: 'Connect your chatbot to Google Sheets for data management and reporting.',
    id: 'googlesheets',
  },
];

const iconMapping = {
  knowledgebase: Database,
  googlesheets: FileExcelOutlined,
  // Add other mappings here as needed
};

const IntegrationCard = ({ icon, title, description, connected, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { organizationId } = useSelector((state) => state.user);

  const handleClick = () => {
    dispatch({
      type: 'SET_SELECTED_INTEGRATION',
      data: { id, organizationId },
    });
    history.push(`/integrations/${id}`);
  };

  return (
    <Col xs={24} sm={12} md={8} lg={8}>
      <div style={{ height: '100%' }} className='integrationBlockStyle'>
        {/* <div style={{ marginBottom: '10px' }}>{icon}</div> */}
        <Title level={4}>{title}</Title>
        <Text type='secondary'>{description}</Text>
        <div style={{ marginTop: '20px' }}>
          <Button type={connected ? 'default' : 'primary'} block onClick={handleClick}>
            {connected ? 'Manage' : 'Connect'}
          </Button>
        </div>
        <Text
          type={connected ? 'success' : 'secondary'}
          style={{ display: 'block', marginTop: '10px' }}
        >
          {connected ? 'Connected' : 'Not Connected'}
        </Text>
      </div>
    </Col>
  );
};

const Integrations = () => {
  const dispatch = useDispatch();
  const privateAxios = usePrivateAxios();
  let { organizationId } = useSelector((state) => state.user);
  const { integrations } = useSelector((state) => state.integrations);

  if (organizationId === 'UwtbDE') {
    organizationId = 'surveyr';
  }

  useEffect(() => {
    const fetchIntegrationsData = async () => {
      try {
        const response = await privateAxios.get(`/api/integrations/${organizationId}`);
        const dbIntegrations = response.data;

        const mergedIntegrations = integrationDefaults.map((integration) => {
          const dbInt = dbIntegrations.find((i) => i.integrationType === integration.id);
          return {
            ...integration,
            icon: iconMapping[integration.id],
            connected: dbInt ? dbInt.isEnabled : false,
            customInstructions: dbInt ? dbInt.customInstructions : '',
          };
        });

        console.log('mergedIntegrations', mergedIntegrations);
        dispatch({
          type: 'FETCH_INTEGRATIONS',
          data: mergedIntegrations,
        });
      } catch (error) {
        console.error('Error fetching integrations:', error);
        StatusNotification(
          'error',
          'Failed to Load Integrations',
          'There was an error loading your integrations. Please refresh the page or try again later.'
        );
      }
    };

    fetchIntegrationsData();
  }, [organizationId, dispatch, privateAxios]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Title level={2}>Integrations</Title>
        <Text>Connect your chatbot with various services to extend its capabilities.</Text>
      </Col>
      {integrations.map((integration, index) => (
        <IntegrationCard key={index} {...integration} />
      ))}
    </Row>
  );
};

export default Integrations;
