import React, { useState, useRef, useEffect } from 'react';
import { Redirect, useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Carousel, Card, Button, Row, Col, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import GlobalColors from '../assets/colors/GlobalColors';
import usePrivateAxios from '../hooks/usePrivateAxios';

import StatusNotification from './StatusNotification';
import LoadingIcon from '../components/LoadingIcon';

const { Option } = Select;
const { Meta } = Card;

const PdfTemplateExplorer = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const carouselRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useLocation();
  const axiosPrivate = usePrivateAxios();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: surveyId } = useParams();

  const { organizationId } = useSelector((state) => state.user);

  const toolTip = (value) => <span>{value}</span>;

  useEffect(() => {
    const controller = new AbortController();
    const getTemplates = async () => {
      try {
        const response = await axiosPrivate.get('/api/pdfme', {
          signal: controller.signal,
        });
        setTemplates(response.data.templates);
      } catch (error) {
        return <Redirect to={state?.from || '/signin'} />;
      }
    };
    getTemplates();

    return () => {
      controller.abort(); // Abort the request on component unmount
    };
  }, []);

  const updateSurveyTemplate = async (template) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      await axiosPrivate.patch(`/api/surveys/link/${surveyId}`, {
        pdfmeTemplateId: template.id,
      });
      const response = await axiosPrivate.post(`/api/ai/thread/create`, {
        organizationId,
        surveyId,
        pdfmeTemplateId: template.id,
        stepGroups: template.stepGroups,
      });
      dispatch({ type: 'SET_THREAD_ID', data: response.data.threadId });
      StatusNotification('success', 'Template successfully linked to survey');
      history.push(`/surveys/report/${surveyId}/true`);
    } catch (error) {
      console.log(error);
      StatusNotification(
        'error',
        'Error linking this surveyr to the template',
        'Please contact support for assitance'
      );
      history.push(`/surveys`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTemplateClick = () => {
    if (selectedTemplate) {
      updateSurveyTemplate(selectedTemplate);
      dispatch({ type: 'SET_SELECTED_PDFME_TEMPLATE', data: selectedTemplate });
    }
  };

  const handleSelectChange = (value) => {
    const template = templates.find((t) => t.id === value);
    setSelectedTemplate(template);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Row gutter={[24, 24]} style={{ height: '80vh' }}>
        <Col span={6}>
          <Row>
            <Col span={24}>
              <h1>Choose a template</h1>
              <p>Choose a template from the list below to start creating your report.</p>
            </Col>
            <Col>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Select a PDF Template'
                onChange={handleSelectChange}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {templates.map((template) => (
                  <Option key={template.id} value={template.id}>
                    {template.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Tooltip
            placement='top'
            color={GlobalColors.mainPurple}
            title={toolTip('Click to assosciate template with Survey')}
          >
            {selectedTemplate && (
              <div>
                <Button
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                  }}
                  // shape='circle'
                  icon={<LeftOutlined />}
                  onClick={() => carouselRef.current.prev()}
                />
                <Carousel ref={carouselRef} style={{ marginTop: 20 }}>
                  {selectedTemplate.thumbnails.map((url, index) => (
                    <Card
                      key={index}
                      hoverable
                      style={{ width: 40, height: 'auto' }}
                      cover={
                        <img
                          alt={`Thumbnail ${index + 1}`}
                          src={url}
                          style={{ height: 440, objectFit: 'cover' }}
                        />
                      }
                      onClick={handleTemplateClick}
                    >
                      <Meta title={`Thumbnail ${index + 1}`} description={url} />
                      {isLoading && <LoadingIcon />}
                    </Card>
                  ))}
                </Carousel>
                <Button
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                  }}
                  // shape='circle'
                  icon={<RightOutlined />}
                  onClick={() => carouselRef.current.next()}
                />
              </div>
            )}
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

export default PdfTemplateExplorer;
