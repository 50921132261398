import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Typography, Space, Card, Input, Form } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import surveyrLogo from '../../assets/images/surveyr-logo-icon.png';
import StatusNotification from '../StatusNotification';
import axios from 'axios'; // Make sure to import axios

const { Title, Paragraph } = Typography;

function GoogleSheets() {
  const [isConnecting, setIsConnecting] = useState(false);
  const location = useLocation();
  let { organizationId } = useSelector((state) => state.user);
  const { integrations } = useSelector((state) => state.integrations);

  if (organizationId === 'UwtbDE') {
    organizationId = 'surveyr';
  }
  // Find the Google Sheets integration from the integrations array
  const googleSheetsIntegration = integrations.find(
    (integration) => integration.id === 'googlesheets'
  );

  const isConnected = googleSheetsIntegration ? googleSheetsIntegration.connected : false;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const error = searchParams.get('error');

    if (success === 'true') {
      StatusNotification(
        'success',
        'Integration Successful',
        'Google Sheets integration was successful!'
      );
    } else if (error) {
      StatusNotification(
        'error',
        'Integration Failed',
        'Failed to integrate with Google Sheets. Please try again.'
      );
    }
  }, [location]);

  const handleConnect = async () => {
    setIsConnecting(true);
    try {
      const response = await axios.get('/api/googlesheets/init-auth', {
        params: { organizationId },
      });
      window.location.href = response.data.authUrl;
    } catch (error) {
      console.error('Error initiating Google Sheets integration:', error);
      StatusNotification(
        'error',
        'Integration Failed',
        'Failed to start Google Sheets integration. Please try again.'
      );
      setIsConnecting(false);
    }
  };

  const handleLinkSheet = async (values) => {
    try {
      await axios.post('/api/googlesheets/link-sheet', {
        organizationId,
        spreadsheetUrl: values.spreadsheetUrl,
      });
      StatusNotification('success', 'Sheet Linked', 'Google Sheet has been successfully linked!');
    } catch (error) {
      console.error('Error linking Google Sheet:', error);
      StatusNotification(
        'error',
        'Linking Failed',
        'Failed to link Google Sheet. Please try again.'
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 64px)', // Adjust this value based on your header height
        padding: '20px',
      }}
    >
      <Card className='google-sheets-integration' style={{ width: '50%', maxWidth: '500px' }}>
        <Space direction='vertical' size='large' style={{ width: '100%', textAlign: 'center' }}>
          {!isConnected ? (
            <>
              <Title level={2}>Google Sheets Integration</Title>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <img
                  src={surveyrLogo}
                  alt='Surveyr Logo'
                  style={{ width: '50px', height: '50px' }}
                />
                <FileExcelOutlined style={{ fontSize: '48px' }} />
              </div>
              <Paragraph>
                Sync responses directly with Google Sheets. Easily export and analyze your survey
                data.
              </Paragraph>

              <Button
                type='primary'
                icon={<FileExcelOutlined />}
                onClick={handleConnect}
                size='large'
                loading={isConnecting}
                disabled={isConnecting}
              >
                {isConnecting ? 'Connecting...' : 'Connect with Google Sheets'}
              </Button>
            </>
          ) : (
            <>
              <Title level={2}>
                <FileExcelOutlined style={{ marginRight: '8px' }} />
                Paste Link
              </Title>
              <Paragraph>
                Paste the link to the Google Sheet you want to link to your organization.
              </Paragraph>
              <Form onFinish={handleLinkSheet}>
                <Form.Item
                  name='spreadsheetUrl'
                  validateTrigger='onBlur'
                  rules={[
                    { required: true, message: 'Please enter the spreadsheet URL' },
                    {
                      type: 'url',
                      message: 'Please enter a valid URL',
                    },
                    {
                      pattern: /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+/,
                      message: 'Please enter a valid Google Sheets URL',
                    },
                  ]}
                >
                  <Input placeholder='https://docs.google.com/spreadsheets/d/<your-spreadsheet-id>' allowClear />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType='submit'>
                    Link Sheet
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </Space>
      </Card>
    </div>
  );
}

export default GoogleSheets;
