import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Upload,
  message,
  Button,
  Input,
  Select,
  Typography,
  Tag,
  Row,
  Col,
  Grid,
  Collapse,
  Tooltip,
  Segmented,
  List,
  Badge,
  Spin,
} from 'antd';
import {
  InboxOutlined,
  EditOutlined,
  InfoCircleFilled,
  KubernetesOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import usePrivateAxios from '../../hooks/usePrivateAxios';
import StatusNotification from '../StatusNotification';
import getScreenSize from '../../utils/getScreenSize';
import SectionHeader from '../SectionHeader';
import GlobalColors from '../../assets/colors/GlobalColors';
import isUrlValid from '../../utils/isUrlValid';
import LoadingIcon from '../LoadingIcon';

import formatFileName from '../../utils/formatFileName';
import { is } from 'date-fns/locale';

const { Dragger } = Upload;
const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const KnowledgeBase = () => {
  const [fileList, setFileList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [url, setUrl] = useState('');
  const [scrapeType, setScrapeType] = useState('scrape');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingState, setProcessingState] = useState({
    isUploading: false,
    isDeleting: false,
    isScraping: false,
    resourceType: null,
    processingItems: new Set(),
  });
  const draggerRef = React.useRef(null);

  const dispatch = useDispatch();
  let { organizationId } = useSelector((state) => state.user);
  const { isConnected, customInstructions, selectedAssistantType } = useSelector(
    (state) => state.integrations.knowledgebase
  );
  const { files, urls } = useSelector((state) => state.integrations.knowledgebase);

  const privateAxios = usePrivateAxios();
  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);
  const history = useHistory();

  if (organizationId === 'UwtbDE') {
    organizationId = 'surveyr';
  }
  const assistantTypes = [
    {
      type: 'Sales Assistant',
      instruction:
        "You are a knowledgeable sales assistant for [TechPro Solutions]. Your role is to provide information about [TechPro Solutions]'s products, answer customer queries, and assist with the sales process. Always be polite, informative, and aim to match [TechPro Solutions]'s products with customer needs. Use the context provided in the user's input to help answer questions when available. If you're unsure about specific product details or if a query is beyond your knowledge, say 'I'm sorry, I don't have that specific information. For the most accurate and up-to-date details, I recommend checking the official [TechPro Solutions] product specifications or speaking with one of our specialized sales representatives.' Don't try to make up an answer. Always speak respectfully and in alignment with [TechPro Solutions]'s values.",
    },
    {
      type: 'Math Tutor',
      instruction:
        "You are a patient and knowledgeable math tutor representing [MathMasters Academy]. Your role is to help [MathMasters Academy] students understand mathematical concepts, solve problems, and develop their problem-solving skills. Provide step-by-step explanations, use analogies when helpful, and encourage students to think critically. Use the context provided in the user's input to help answer questions when available. If a concept is beyond your scope or if you're unsure about a particular problem, say 'I'm sorry, I don't have enough information to provide a complete answer for this. For more advanced topics or specific curriculum questions, I recommend consulting with your [MathMasters Academy] teacher or referring to a specialized textbook recommended by [MathMasters Academy].' Don't try to make up an answer. Always speak respectfully and in alignment with [MathMasters Academy]'s educational philosophy.",
    },
    {
      type: 'School Assistant',
      instruction:
        "You are a helpful school assistant representing [MathMasters Academy]. Your role is to provide general information about the school, assist both teachers and students with inquiries, and facilitate communication within the school community. Use the context provided in the user's input to help answer questions when available. If you're unsure about a specific detail or if a question is beyond your knowledge, say 'I'm sorry, I don't have that information. For more specific inquiries, I recommend reaching out to the appropriate school staff or checking the official [MathMasters Academy] website.' Don't try to make up an answer. Always speak respectfully and in alignment with [MathMasters Academy]'s values.",
    },
    {
      type: 'Fitness Coach',
      instruction:
        "You are a motivational fitness coach for [FitLife Gym]. Your role is to provide exercise advice, create workout plans, and offer nutritional guidance in line with [FitLife Gym]'s philosophy. Always prioritize safety and encourage gradual progress. Use the context provided in the user's input to help answer questions when available. If you're unsure about a specific fitness query or if it involves medical considerations, say 'I'm sorry, I don't have enough information to provide a complete answer for this. For personalized advice, especially regarding pre-existing health conditions, I strongly recommend consulting with a doctor or speaking with one of our certified [FitLife Gym] trainers.' Don't try to make up an answer. Always speak respectfully, promote [FitLife Gym]'s facilities and classes when appropriate, and align with [FitLife Gym]'s values.",
    },
    {
      type: 'Travel Advisor',
      instruction:
        "You are an experienced travel advisor for [Wanderful Voyages]. Your role is to provide travel recommendations, tips on destinations, and help with travel planning using [Wanderful Voyages]' services. Offer insights on local cultures, must-see attractions, and travel logistics. Use the context provided in the user's input to help answer questions when available. If you're unsure about specific details or current situations in a destination, say 'I'm sorry, I don't have the most up-to-date information on this. For the latest travel advisories and specific details, I recommend checking with our [Wanderful Voyages] travel specialists or consulting official travel websites.' Don't try to make up an answer. Always speak respectfully, emphasize the importance of respecting local customs, and highlight [Wanderful Voyages]' special offers and unique travel packages when relevant.",
    },
    {
      type: 'Tech Support',
      instruction:
        "You are a patient tech support assistant for [ByteBridge IT Solutions]. Your role is to help [ByteBridge IT Solutions] clients troubleshoot technology issues, provide step-by-step guidance, and explain technical concepts in simple terms. Use the context provided in the user's input to help answer questions when available. If a problem seems too complex, potentially harmful to the device, or if you're unsure about the solution, say 'I'm sorry, I don't have enough information to safely resolve this issue. For complex problems like this, I strongly advise contacting [ByteBridge IT Solutions]' official support channels or a certified technician to prevent any potential damage or data loss.' Don't try to make up an answer. Always speak respectfully, prioritize [ByteBridge IT Solutions]' recommended solutions and products, and align with the company's values.",
    },
    {
      type: 'Cooking Guide',
      instruction:
        "You are a friendly cooking guide for [Gourmet Grove Culinary School]. Your role is to provide recipes, cooking tips, and answer culinary questions in line with [Gourmet Grove]'s teaching philosophy. Offer substitution suggestions for ingredients, explain cooking techniques, and provide guidance on food safety. Use the context provided in the user's input to help answer questions when available. If you're unsure about a specific culinary technique, recipe, or if a query involves potential allergies or dietary restrictions, say 'I'm sorry, I don't have enough information to provide a complete or safe answer for this. For specialized dietary advice or advanced culinary techniques, I recommend consulting with a [Gourmet Grove] chef instructor or a certified nutritionist.' Don't try to make up an answer. Always speak respectfully, remind users to double-check ingredient lists if they have allergies, and promote [Gourmet Grove]'s courses and cooking tools when appropriate.",
    },
    {
      type: 'Church Assistant',
      instruction:
        "You are a helpful assistant for [Seventh Day Adventist Church]. Your role is to answer questions and provide information based on the CONTEXT provided based on [Seventh Day Adventist Church]'s beliefs, practices, and church activities. Use the context provided in the user's input to help answer questions when available. If you really don't know the answer, say 'I'm sorry, I don't have that information. You may want to speak with one of our pastors or elders for more details.' Don't try to make up an answer. Always speak respectfully and in alignment with [Seventh Day Adventist Church]'s values.",
    },
  ];

  useEffect(() => {
    fetchIntegrationStatus();
  }, []);

  useEffect(() => {
    fetchKnowledgeBases();
  }, []);

  useEffect(() => {
    const preventDefault = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const isValidDropTarget = (target) => {
      return target.closest('.custom-file-dragger') !== null;
    };

    const handleDrop = (e) => {
      preventDefault(e);

      if (!isValidDropTarget(e.target)) {
        StatusNotification(
          'warning',
          'Invalid Drop Area',
          'Please drop files only in the designated upload area.'
        );
      }
    };

    const handleDragOver = (e) => {
      preventDefault(e);

      if (!isValidDropTarget(e.target)) {
        e.dataTransfer.dropEffect = 'none';
      }
    };

    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    if (selectedAssistantType) {
      const selectedAssistant = assistantTypes.find((a) => a.type === selectedAssistantType);
      if (selectedAssistant) {
        dispatch({
          type: 'SET_KNOWLEDGEBASE_INSTRUCTIONS',
          data: selectedAssistant.instruction,
        });
      }
    }
  }, [selectedAssistantType, dispatch]);

  const fetchIntegrationStatus = async () => {
    try {
      const response = await privateAxios.get(`/api/integrations/${organizationId}`);
      const dbIntegrations = response.data;
      const knowledgeBaseIntegration = dbIntegrations.find(
        (i) => i.integrationType === 'knowledgebase'
      );

      dispatch({
        type: 'SET_KNOWLEDGEBASE_STATUS',
        data: {
          isConnected: knowledgeBaseIntegration ? knowledgeBaseIntegration.isEnabled : false,
          customInstructions: knowledgeBaseIntegration
            ? knowledgeBaseIntegration.customInstructions
            : '',
        },
      });
    } catch (error) {
      console.error('Error fetching integration status:', error);
      StatusNotification(
        'error',
        'Failed to Load Integration Status',
        'There was an error loading your integration status. Please refresh the page or try again later.'
      );
    }
  };

  const fetchKnowledgeBases = async () => {
    console.log('updating fetchKnowledgeBases');
    try {
      const response = await privateAxios.get(`/api/integrations/${organizationId}`);
      const knowledgeBaseIntegration = response.data.find(
        (i) => i.integrationType === 'knowledgebase'
      );
      if (knowledgeBaseIntegration && knowledgeBaseIntegration.config.files) {
        dispatch({
          type: 'SET_KNOWLEDGEBASE_FILES',
          data: knowledgeBaseIntegration.config.files,
        });
      }

      if (knowledgeBaseIntegration && knowledgeBaseIntegration.config.urls) {
        dispatch({
          type: 'SET_KNOWLEDGEBASE_URLS',
          data: knowledgeBaseIntegration.config.urls,
        });
      }
    } catch (error) {
      console.error('Error fetching knowledgebases:', error);
      const message = error.response?.data?.message || 'Failed to load knowledgebases';
      StatusNotification('error', 'Failed to load existing knowledgebases', message);
    }
  };

  const handleDeleteFile = async (file) => {
    setProcessingState((prevState) => ({
      ...prevState,
      isDeleting: true,
      resourceType: 'file',
      processingItems: new Set(prevState.processingItems).add(file.originalName),
    }));
    try {
      await privateAxios.delete(
        `/api/integrations/${organizationId}/knowledgebase/files/${file.originalName}`
      );
      dispatch({
        type: 'SET_KNOWLEDGEBASE_FILES',
        data: files.filter((f) => f.publicId !== file.publicId),
      });
      StatusNotification('success', 'File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
      StatusNotification('error', 'Failed to delete file');
    } finally {
      setProcessingState((prevState) => ({
        ...prevState,
        isDeleting: false,
        resourceType: null,
        processingItems: new Set(
          [...prevState.processingItems].filter((item) => item !== file.originalName)
        ),
      }));
    }
  };
  const handleDeleteUrls = async (url) => {
    setProcessingState((prevState) => ({
      ...prevState,
      isDeleting: true,
      resourceType: 'url',
      processingItems: new Set(prevState.processingItems).add(url),
    }));
    await new Promise((resolve) => setTimeout(resolve, 7000));
    try {
      await privateAxios.delete(`/api/integrations/${organizationId}/knowledgebase/urls`, {
        data: { url },
      });
      dispatch({
        type: 'SET_KNOWLEDGEBASE_URLS',
        data: urls.filter((u) => u !== url),
      });
      StatusNotification('success', 'URL deleted successfully');
    } catch (error) {
      console.error('Error deleting URL :', error);
      StatusNotification('error', 'Failed to delete URL');
    } finally {
      fetchKnowledgeBases();
      setProcessingState((prevState) => ({
        ...prevState,
        isDeleting: false,
        resourceType: null,
        processingItems: new Set([...prevState.processingItems].filter((item) => item !== url)),
      }));
    }
  };

  const handleFileUpload = async (file) => {
    setProcessingState((prevState) => ({
      ...prevState,
      isUploading: true,
      resourceType: 'file',
    }));

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', formatFileName(file.name));
    formData.append('organizationId', organizationId);
    formData.append('integrationType', 'knowledgebase');
    formData.append('isEnabled', 'true');

    try {
      let response;
      if (isConnected) {
        response = await privateAxios.patch(
          `/api/integrations/${organizationId}/knowledgebase`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        response = await privateAxios.post(
          `/api/integrations/${organizationId}/knowledgebase`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201)
        StatusNotification('success', 'Upload Successful', 'File successfully uploaded');
    } catch (error) {
      console.error('Error uploading file:', error);
      const message = error.response?.data?.message || 'Failed to upload file';
      StatusNotification('error', 'Upload Failed', message);
    } finally {
      fetchKnowledgeBases();
      setProcessingState((prevState) => ({
        ...prevState,
        isUploading: false,
        resourceType: null,
      }));
    }
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value.toLowerCase().trim());
  };

  const handleScrapeUrl = async () => {
    if (!isUrlValid(url)) {
      StatusNotification('error', 'Invalid URL', 'Please enter a valid URL');
      return;
    }

    setProcessingState((prevState) => ({
      ...prevState,
      isScraping: true,
      resourceType: 'url',
    }));

    //3 seconds delay
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const payload = {
      url,
      organizationId,
      integrationType: 'knowledgebase',
      isEnabled: true,
      customInstructions,
    };
    try {
      let response;
      if (isConnected) {
        response = await privateAxios.patch(`/api/integrations/${organizationId}/website`, payload);
      } else {
        response = await privateAxios.post(`/api/integrations/${organizationId}/website`, payload);
      }

      if (response.status === 201 || response.status === 200) {
        StatusNotification('success', 'Upload Successful', 'URL successfully added to integration');
        setUrl('');
      }
    } catch (error) {
      console.error('Error adding URL to integration:', error);
      const message = error.response?.data?.message || 'Failed to add URL to integration';
      StatusNotification('error', 'Upload Failed', message);
    } finally {
      fetchKnowledgeBases();
      setProcessingState((prevState) => ({
        ...prevState,
        isScraping: false,
        resourceType: null,
      }));
    }
  };
  const handleInstructions = async () => {
    try {
      const response = await privateAxios.patch(
        `/api/integrations/${organizationId}/knowledgebase`,
        { customInstructions }
      );

      if (response.status === 200) {
        StatusNotification(
          'success',
          'Update Successful',
          'Successfully updated knowledge base instructions'
        );
        dispatch({
          type: 'SET_KNOWLEDGEBASE_STATUS',
          data: {
            isConnected: true,
            customInstructions: customInstructions,
          },
        });
        history.goBack();
      }
    } catch (error) {
      console.error('Error updating instructions:', error);
      const message = error.response?.data?.message || 'Failed to update instructions';
      StatusNotification('error', 'Update Failed', message);
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    fileList,
    action: () => false,
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      const isTXT = file.type === 'text/plain';
      const isValidType = isPDF || isTXT;
      const isLt20M = file.size / 1024 / 1024 < 20;

      if (!isValidType) {
        StatusNotification('error', 'Invalid file type', `${file.name} is not a PDF or TXT file`);
      }
      if (!isLt20M) {
        StatusNotification('error', 'File too large', 'File must be smaller than 20MB!');
      }

      if (isValidType && isLt20M) {
        const sanitizedFileName = formatFileName(file.name);
        const fileWithSanitizedName = new File([file], sanitizedFileName, { type: file.type });
        setFileList([fileWithSanitizedName]);
        handleFileUpload(fileWithSanitizedName);
      }

      return false;
    },
    onRemove: () => {
      setFileList([]);
    },
    onChange: (info) => {
      const { status } = info.file;
      if (status === 'done') {
        dispatch({
          type: 'ADD_KNOWLEDGEBASE_FILE',
          data: info.file.response,
        });
        StatusNotification('success', `${info.file.name} file uploaded successfully.`);
        setFileList([]); // Clear the fileList after successful upload
      } else if (status === 'error') {
        StatusNotification('error', `${info.file.name} file upload failed.`);
        setFileList([]); // Clear the fileList if upload fails
      }
    },
  };

  const handleSelectChange = (value) => {
    dispatch({
      type: 'SET_SELECTED_ASSISTANT_TYPE',
      data: value,
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleTextAreaChange = (e) => {
    dispatch({
      type: 'SET_KNOWLEDGEBASE_INSTRUCTIONS',
      data: e.target.value,
    });
    if (
      e.target.value !== assistantTypes.find((a) => a.type === selectedAssistantType)?.instruction
    ) {
      dispatch({
        type: 'SET_SELECTED_ASSISTANT_TYPE',
        data: '',
      });
    }
  };

  const getProcessingMessage = () => {
    const { isUploading, isDeleting, isScraping, resourceType } = processingState;
    if (isUploading && resourceType === 'file') return 'Uploading file and training bot 🤖 ...';
    if (isDeleting && resourceType === 'file') return 'Deleting file...';
    if (isScraping && resourceType === 'url') return 'Scraping website and training bot 🤖 ...';
    if (isDeleting && resourceType === 'url') return 'Deleting URL...';
    return 'Processing...';
  };

  const replaceCompanyName = () => {
    if (companyName) {
      dispatch({
        type: 'SET_KNOWLEDGEBASE_INSTRUCTIONS',
        data: customInstructions.replace(/\[([^\]]+)\]/g, `[${companyName}]`),
      });
    }
  };

  const toolTip = (value) => <span>{value}</span>;

  console.log({ url });
  return (
    <Row gutter={[8, 0]} style={{ marginTop: screenSize !== 'xs' ? 48 : 0 }}>
      <Col span={24}>
        <Row>
          <Col xs={24} md={12}>
            <SectionHeader
              title={'Knowledge Base'}
              isFront={false}
              subtitle=''
              style={{ marginTop: screenSize !== 'xs' ? 48 : 0 }}
            />
          </Col>
        </Row>
      </Col>

      <Col xs={24} md={12}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Text>Select an assistant type or customize the instructions:</Text>
            <Select
              style={{ width: '100%', marginBottom: 16, marginTop: 8 }}
              placeholder='Select an assistant type'
              onChange={handleSelectChange}
              value={selectedAssistantType}
            >
              {assistantTypes.map((assistant) => (
                <Option key={assistant.type} value={assistant.type}>
                  {assistant.type}
                </Option>
              ))}
            </Select>

            <TextArea
              placeholder='Enter or customize instruction'
              value={customInstructions}
              onChange={handleTextAreaChange}
              style={{ marginBottom: 16 }}
              rows={10}
            />
          </Col>

          <Col span={24}>
            <Row>
              <Col xs={24} md={12}>
                <Text type='secondary'>
                  Replace the [bracketed] company names with your own brand or company name:
                </Text>
              </Col>
              <Col xs={24} md={12}>
                <Row justify='space-between' align='middle'>
                  <Col span={20}>
                    <Input
                      placeholder='Enter your company name'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      style={{ width: '100%', height: 40 }}
                    />
                  </Col>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    <EditOutlined
                      onClick={replaceCompanyName}
                      style={{ fontSize: 18, cursor: 'pointer' }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Collapse>
              <Panel
                header='Upload custom documents'
                key='1'
                extra={
                  <>
                    {files.length > 0 && (
                      <Badge count={files.length} color={GlobalColors.lightPurple} />
                    )}
                  </>
                }
              >
                <Dragger
                  ref={draggerRef}
                  className='custom-file-dragger'
                  {...props}
                  style={{ backgroundColor: GlobalColors.whitePurple }}
                >
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text'>
                    Click or drag PDF or TXT file to this area to upload
                  </p>
                  <p className='ant-upload-hint'>
                    Support for a single PDF or TXT file upload (max 20MB). To train your chatbot
                    assistant
                  </p>
                </Dragger>
                {(processingState.isUploading || processingState.isDeleting) &&
                processingState.resourceType === 'file' ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Tag color={GlobalColors.mainPurple}>{getProcessingMessage()}</Tag>
                    <LoadingIcon color={GlobalColors.mainPurple} fontSize={14} />
                  </div>
                ) : (
                  <List
                    itemLayout='horizontal'
                    dataSource={files}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <DeleteOutlined
                            onClick={() => handleDeleteFile(item)}
                            style={{ fontSize: 18 }}
                          />,
                        ]}
                      >
                        <List.Item.Meta
                          title={
                            <a href={item.url} target='_blank' rel='noopener noreferrer'>
                              {item.originalName}
                            </a>
                          }
                          description={`Size: ${(item.size / 1024).toFixed(2)} KB`}
                        />
                      </List.Item>
                    )}
                  />
                )}
              </Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Collapse>
              <Panel
                header={
                  <>
                    {'scrape or crawl website '}
                    <Tooltip
                      placement='top'
                      color={GlobalColors.mainPurple}
                      title={toolTip(
                        'Note: It is illegal to scrape or crawl websites without permission. 👮🏻 We will not be held responsible for any misuse of this service.'
                      )}
                    >
                      <InfoCircleFilled style={{ fontSize: 12, color: GlobalColors.failed }} />
                    </Tooltip>
                  </>
                }
                key='2'
                extra={
                  <>
                    {urls?.length > 0 && (
                      <Badge count={urls?.length} color={GlobalColors.lightPurple} />
                    )}
                  </>
                }
              >
                <Row gutter={[8, 16]}>
                  <Col xs={24} md={6}>
                    <Segmented
                      size={'small'}
                      style={{ borderRadius: 4 }}
                      value={scrapeType}
                      options={[
                        { label: 'Scrape', value: 'scrape' },
                        { label: 'Crawl', value: 'crawl' },
                      ]}
                      onChange={(value) => setScrapeType(value)}
                    />
                  </Col>
                  <Col xs={20} md={16}>
                    <Input
                      placeholder='Enter your website address'
                      value={url}
                      onChange={handleUrlChange}
                      style={{ height: 40 }}
                    />
                  </Col>
                  <Col span={2} style={{ textAlign: 'center' }}>
                    <KubernetesOutlined
                      style={{ fontSize: 38, color: GlobalColors.lightPurple, cursor: 'pointer' }}
                      onClick={handleScrapeUrl}
                    />
                  </Col>
                </Row>
                {(processingState.isScraping || processingState.isDeleting) &&
                processingState.resourceType === 'url' ? (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Tag color={GlobalColors.mainPurple}>{getProcessingMessage()}</Tag>
                    <LoadingIcon color={GlobalColors.mainPurple} fontSize={14} />
                  </div>
                ) : (
                  <List
                    itemLayout='horizontal'
                    dataSource={urls}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <DeleteOutlined
                            onClick={() => handleDeleteUrls(item)}
                            style={{ fontSize: 18 }}
                          />,
                        ]}
                      >
                        <List.Item.Meta title={<>{item}</>} />
                      </List.Item>
                    )}
                  />
                )}
              </Panel>
            </Collapse>
          </Col>
          <Col span={24}>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Button
                  type='primary'
                  onClick={handleInstructions}
                  style={{ marginTop: '20px' }}
                  block
                >
                  {isConnected ? 'Update' : 'Save'}
                </Button>
              </Col>
              <Col span={12}>
                <Button onClick={handleCancel} style={{ marginTop: '20px' }} block>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default KnowledgeBase;
