import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Upload, Button, Row, Col, Typography, Badge } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StatusNotification from './StatusNotification';
import determineFileType from '../utils/determineFileType';
import usePrivateAxios from '../hooks/usePrivateAxios';

const { Text } = Typography;

const Media = ({ fileName, organizationId, usage, form, fieldName, setImageUrl, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();

  const handleUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const uploadFile = async (file, index) => {
      const fileType = determineFileType(file.type);
      const filePrefix = fileType === 'document' ? 'doc' : fileType;

      console.log({ fileType });

      const rootFolderName = dayjs().format('YYYY');
      const subFolderName = dayjs().format('MM');
      const fileExtension = file.name.split('.').pop();
      const fileNameWithoutExtension = file.name.slice(0, -(fileExtension.length + 1));
      const filePublicId = `surveyr/${rootFolderName}/${subFolderName}/${organizationId}/${filePrefix}_${fileNameWithoutExtension}_${usage}_${index}`;

      try {
        // Check if asset exists
        const response = await axiosPrivate.post(`/api/cloudinary/checkasset`, {
          fileType,
          filePublicId,
        });

        if (!response.data.canProceed)
          return StatusNotification(
            'error',
            'Error uploading file to the cloud',
            'Please try again later'
          );

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'hgzzn6q0');
        formData.append('public_id', filePublicId);
        const res = await axios.post(process.env.REACT_APP_CLOUDINARY_URL + '/upload', formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress({ percent: Math.round((loaded / total) * 100) }, file);
          },
        });

        const { url } = res.data;
        console.log({ url });
        // onChange(url);
        return url;
      } catch (error) {
        console.log(error);
        onError(error);
        StatusNotification(
          'error',
          'Error uploading file to the cloud',
          error?.response?.data?.message
        );
        throw error;
      }
    };

    try {
      setIsLoading(true);
      let urls = [];
      let fileType;

      if (Array.isArray(file)) {
        const uploadPromises = file.map((f, index) => uploadFile(f, index));
        urls = await Promise.all(uploadPromises);
        fileType = file.length > 0 ? determineFileType(file[0].type) : 'unknown';
      } else {
        const url = await uploadFile(file, 0);
        urls = [url];
        fileType = determineFileType(file.type);
      }
      switch (usage) {
        case 'Welcome':
          dispatch({
            type: 'CREATE_SURVEY_WELCOME_MEDIA',
            data: { url: urls[0], welcomeFileType: fileType },
          });
          break;
        case 'Close':
          dispatch({
            type: 'CREATE_SURVEY_CLOSE_MEDIA',
            data: { url: urls[0], closingFileType: fileType },
          });
          break;
        case 'Template':
          dispatch({
            type: 'SET_TEMPLATE_MEDIA_URL',
            data: { templateMediaUrl: urls[0], mediaType: fileType },
          });
          break;
        case 'Option':
          console.log('Updating form for Option media:', { fieldName, url: urls[0] });
          form.setFieldsValue({
            options: {
              ...form.getFieldValue('options'),
              [fieldName[1]]: {
                ...form.getFieldValue(['options', fieldName[1]]),
                optionMediaUrl: urls[0],
              },
            },
          });
          console.log('Form values after update:', form.getFieldValue('options'));
          break;
        case 'gpt-vision':
          setImageUrl(urls[0]);
          break;
        default:
          break;
      }

      onSuccess(urls, file);
      StatusNotification('success', 'File(s) successfully uploaded to the cloud');
    } catch (error) {
      onError(error);
      console.log({ error });
      StatusNotification(
        'error',
        'Error uploading file(s) to the cloud',
        error?.response?.data?.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const props = {
    name: 'file',
    customRequest: handleUpload,
    multiple: usage === 'gpt-vision',
    beforeUpload: (file, fileList) => {
      if (usage === 'gpt-vision') {
        const invalidFiles = fileList.filter((file) => !file.type.startsWith('image/'));
        if (invalidFiles.length > 0) {
          StatusNotification('error', 'Only images can be converted into surveys');
          return Upload.LIST_IGNORE;
        }
      } else {
        const fileType = file.type.split('/')[0];
        const fileExtension = file.type.split('/')[1];
        const acceptedFormats = ['jpeg', 'jpg', 'png'];

        if (fileType === 'video' && fileExtension !== 'mp4') {
          StatusNotification('error', 'Only mp4 videos are supported');
          return Upload.LIST_IGNORE;
        }

        if (fileType === 'video') {
          const isLt32M = file.size / 1024 / 1024 < 32;
          if (!isLt32M) {
            StatusNotification('error', 'Video must be smaller than 16MB!');
            return Upload.LIST_IGNORE;
          }
        }

        if (fileType !== 'image' && file.type !== 'application/pdf' && fileType !== 'video') {
          StatusNotification('error', `${fileType} is not a supported format`);
          return Upload.LIST_IGNORE;
        }

        if (fileType === 'image') {
          const isLt4M = file.size / 1024 / 1024 < 4;
          if (!acceptedFormats.includes(fileExtension)) {
            StatusNotification('error', 'Only .jpg, .jpeg, and .png images are allowed');
            return Upload.LIST_IGNORE;
          }
          if (!isLt4M) {
            StatusNotification('error', 'Image must be smaller than 1MB!');
            return Upload.LIST_IGNORE;
          }
        }

        if (file.type === 'application/pdf') {
          const isLt12M = file.size / 1024 / 1024 < 12;
          if (!isLt12M) {
            StatusNotification('error', 'PDF must be smaller than 2MB!');
            return Upload.LIST_IGNORE;
          }
        }
      }

      setIsLoading(true);
      return true;
    },
    onRemove: () => {
      if (usage === 'Option') form.setFieldsValue({ [fieldName]: undefined });
      setIsLoading(false);
      setIsUploaded(false);
    },
  };

  const getContentText = () => {
    const usageTextMap = {
      Welcome: 'Welcome Media',
      Template: 'Template Media',
      WhatsApp: 'WhatsApp Media',
      Option: 'Option Media',
      'gpt-vision': 'Survey Form',
    };

    return usageTextMap[usage] || 'Bonus content';
  };

  const getButtonText = () => {
    if (isLoading) return 'Uploading...';
    if (isUploaded) return 'Uploaded';

    const buttonTextMap = {
      Welcome: 'Upload Welcome Media',
      Template: 'Upload Template Media',
      WhatsApp: 'Upload WhatsApp Media',
      Option: 'Upload Option Media',
      'gpt-vision': 'Upload image(s) to turn into a survey',
    };

    return buttonTextMap[usage] || 'Upload Bonus content';
  };

  // Then use these functions to set the content before the return statement
  const contentText = getContentText();
  const buttonText = getButtonText();

  return (
    <Row>
      <Col span={24} style={{ backgroundColor: 'Background', borderRadius: 6 }}>
        <Row>
          <Col
            span={24}
            style={
              contentText.toLowerCase().includes('bonus') ? { paddingRight: 8 } : { paddingLeft: 8 }
            }
          >
            <Text strong style={{ fontSize: 12 }}>
              {contentText}
            </Text>
          </Col>
          <Col
            span={24}
            style={
              contentText.toLowerCase().includes('bonus')
                ? { paddingRight: 8, paddingBottom: 8 }
                : { paddingLeft: 8, paddingBottom: 8 }
            }
          >
            <Upload {...props} style={{ cursor: 'pointer', width: '100%', marginBottom: 14 }}>
              <Button icon={<UploadOutlined />} disabled={isLoading || isUploaded}>
                {buttonText}
              </Button>
            </Upload>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Media;
