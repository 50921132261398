import {
  FETCH_INTEGRATIONS,
  CREATE_INTEGRATION,
  UPDATE_INTEGRATION,
  DELETE_INTEGRATION,
  SET_SELECTED_INTEGRATION,
  CLEAR_SELECTED_INTEGRATION,
  UPDATE_KNOWLEDGEBASE,
  SET_KNOWLEDGEBASE_FILES,
  SET_KNOWLEDGEBASE_STATUS,
  SET_SELECTED_ASSISTANT_TYPE,
  SET_KNOWLEDGEBASE_INSTRUCTIONS,
  SET_KNOWLEDGEBASE_URLS,
} from '../actions/types';

const initialState = {
  integrations: [],
  selectedIntegration: null,
  knowledgebase: {
    files: [],
    urls: [],
    customInstructions: '',
    isConnected: false,
    selectedAssistantType: '',
  },
};

const integrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INTEGRATIONS:
      return {
        ...state,
        integrations: action.data,
      };
    case CREATE_INTEGRATION:
      return {
        ...state,
        integrations: [...state.integrations, action.data],
      };
    case UPDATE_INTEGRATION:
      return {
        ...state,
        integrations: state.integrations.map((integration) =>
          integration.organizationId === action.data.organizationId &&
          integration.integrationType === action.data.integrationType
            ? action.data
            : integration
        ),
      };
    case DELETE_INTEGRATION:
      return {
        ...state,
        integrations: state.integrations.filter(
          (integration) =>
            !(
              integration.organizationId === action.data.organizationId &&
              integration.integrationType === action.data.integrationType
            )
        ),
      };
    case SET_SELECTED_INTEGRATION:
      return {
        ...state,
        selectedIntegration: action.data,
      };
    case CLEAR_SELECTED_INTEGRATION:
      return {
        ...state,
        selectedIntegration: null,
      };
    case UPDATE_KNOWLEDGEBASE:
      return {
        ...state,
        knowledgebase: {
          ...state.knowledgebase,
          customInstructions: action.data.customInstructions,
        },
      };
    case SET_KNOWLEDGEBASE_STATUS:
      return {
        ...state,
        knowledgebase: {
          ...state.knowledgebase,
          isConnected: action.data.isConnected,
          customInstructions: action.data.customInstructions,
        },
      };
    case SET_SELECTED_ASSISTANT_TYPE:
      return {
        ...state,
        knowledgebase: {
          ...state.knowledgebase,
          selectedAssistantType: action.data,
        },
      };
    case SET_KNOWLEDGEBASE_INSTRUCTIONS:
      console.log({ action });
      return {
        ...state,
        knowledgebase: {
          ...state.knowledgebase,
          customInstructions: action.data,
        },
      };
    case SET_KNOWLEDGEBASE_FILES:
      return {
        ...state,
        knowledgebase: {
          ...state.knowledgebase,
          files: action.data,
        },
      };
    case SET_KNOWLEDGEBASE_URLS:
      return {
        ...state,
        knowledgebase: {
          ...state.knowledgebase,
          urls: action.data,
        },
      }
    default:
      return state;
  }
};

export default integrationsReducer;
