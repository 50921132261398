import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Space, message, Form, Checkbox, Tooltip, Row, Col, Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';
import Emojis from '../seed/Emojis';
import { MAX_OPTIONS } from '../constants';

const { Option } = Select;

const OptionType = 'OPTION';

const DraggableOption = ({
  id,
  index,
  moveItem,
  children,
  isQuiz,
  isCorrect,
  onCheckboxChange,
}) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: OptionType,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: OptionType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity, marginBottom: 8 }}>
      <Space
        align='center'
        style={{
          width: '100%',
          backgroundColor: isQuiz ? (isCorrect ? '#a3d1ff' : '#fcccc0') : '#f0f2ff',
          paddingTop: 8,
          paddingLeft: 12,
          borderRadius: 4,
        }}
      >
        <MenuOutlined style={{ cursor: 'move', marginBottom: 8 }} />
        {children}
        {isQuiz && (
          <Tooltip placement='top' title='Mark as correct answer'>
            <Checkbox checked={isCorrect} onChange={onCheckboxChange} style={{ marginBottom: 8 }} />
          </Tooltip>
        )}
      </Space>
    </div>
  );
};

const SurveyFormBasic = ({
  question,
  surveyNumber,
  updateQuestionText,
  removeQuestion,
  setOpen,
}) => {
  const [options, setOptions] = useState([]);
  const [useEmoji, setUseEmoji] = useState(false);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(null);
  const [numberOfOptions, setNumberOfOptions] = useState(0);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { create } = useSelector((state) => state.surveysData);
  const { surveyInputData, completed, emojis, isQuiz } = create;

  useEffect(() => {
    let currentForm = surveyInputData[surveyNumber];
    if (currentForm) {
      setUseEmoji(emojis[surveyNumber]);
      form.setFieldsValue(currentForm);
      setNumberOfOptions(Object.keys(currentForm?.options ?? {}).length);
      setOptions(currentForm.options || []);
      setCorrectOptionIndex(currentForm.correctAnswerOption);
    }
  }, [surveyInputData, surveyNumber, emojis, form]);

  useEffect(() => {
    form.setFieldsValue({
      mainQuestion: question.text,
      options: options.map((o) => ({
        value: o.value,
        description: o.description,
        emojiType: o.emojiType, // Add this line
      })),
    });
  }, [question, options, form]);
  const onFinish = (values) => {
    const formattedOptions = options.map((option) => {
      const formattedOption = {
        id: option.id,
        description: option.description,
      };

      if (useEmoji) {
        formattedOption.emojiType = option.emojiType;
        formattedOption.value = Emojis[option.emojiType];
      } else {
        formattedOption.value = option.value;
      }

      if (isQuiz) {
        formattedOption.isCorrect = option.isCorrect;
      }

      return formattedOption;
    });

    const formattedValues = {
      mainQuestion: values.mainQuestion,
      options: formattedOptions,
      type: 'optionList',
      subHeading: 'Question ' + surveyNumber,
      useEmoji: useEmoji,
    };

    if (isQuiz) {
      formattedValues.correctAnswerOption = formattedOptions.findIndex((o) => o.isCorrect);
    }

    emojis[surveyNumber] = useEmoji;
    dispatch({ type: 'CREATE_SURVEY_SET_EMOJIS', data: emojis });

    surveyInputData[surveyNumber] = formattedValues;
    dispatch({ type: 'CREATE_SURVEY_INPUT_DATA', data: surveyInputData });

    completed[surveyNumber] = true;
    dispatch({ type: 'CREATE_SURVEY_COMPLETED', data: completed });

    setOpen([surveyNumber + 1]);

  };

  const moveOption = useCallback(
    (dragIndex, hoverIndex) => {
      setOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        const [reorderedItem] = newOptions.splice(dragIndex, 1);
        newOptions.splice(hoverIndex, 0, reorderedItem);

        // Update correct option index if this is a quiz question
        if (question.isQuiz) {
          let newCorrectOptionIndex = correctOptionIndex;
          if (correctOptionIndex === dragIndex) {
            newCorrectOptionIndex = hoverIndex;
          } else if (dragIndex < correctOptionIndex && hoverIndex >= correctOptionIndex) {
            newCorrectOptionIndex--;
          } else if (dragIndex > correctOptionIndex && hoverIndex <= correctOptionIndex) {
            newCorrectOptionIndex++;
          }
          setCorrectOptionIndex(newCorrectOptionIndex);
        }

        return newOptions;
      });
    },
    [question.isQuiz, correctOptionIndex]
  );

  const handleQuestionTextChange = (e) => {
    const text = e.target.value;
    // if (text.length <= 50) {
    updateQuestionText(question.id, text);
    // } else if (text.length === 51) {
    // updateQuestionText(question.id, text.slice(0, 50) + '...');
    // }
  };

  const setCorrectAnswer = (optionIndex) => {
    setCorrectOptionIndex(optionIndex);
    setOptions(options.map((o, index) => ({ ...o, isCorrect: index === optionIndex })));
  };
  const addOption = () => {
    const newOption = { id: Date.now().toString(), value: '', description: '' };
    setOptions([...options, newOption]);
    setNumberOfOptions((prev) => prev + 1);
  };

  const removeOption = (optionId) => {
    setOptions(options.filter((o) => o.id !== optionId));
    setNumberOfOptions((prev) => prev - 1);
    // Adjust correctOptionIndex if needed
    if (question.isQuiz) {
      const removedIndex = options.findIndex((o) => o.id === optionId);
      if (removedIndex < correctOptionIndex) {
        setCorrectOptionIndex((prev) => prev - 1);
      } else if (removedIndex === correctOptionIndex) {
        setCorrectOptionIndex(null);
      }
    }
  };
  const updateOptionText = (optionId, value, field = 'value') => {
  setOptions(
    options.map((o) => {
      if (o.id === optionId) {
        if (field === 'description') {
          return { ...o, description: value };
        } else if (useEmoji) {
         return { ...o, emojiType: value, value: Emojis[value] };
        } else {
          return { ...o, value: value, emojiType: undefined };
        }
      }
      return o;
    })
  );
};

const handleUseEmojiChange = (e) => {
  const newUseEmoji = e.target.checked;
  setUseEmoji(newUseEmoji);
  setOptions(
    options.map((option) => ({
      ...option,
      value: newUseEmoji ? '' : option.value,
      emojiType: newUseEmoji ? option.emojiType : undefined,
    }))
  );
  form.setFieldsValue({
    options: options.map((option) => ({
      ...option,
      value: newUseEmoji ? '' : option.value,
      emojiType: newUseEmoji ? option.emojiType : undefined,
    })),
  });
};

  const resetOptions = () => {
    setOptions([]);
    setCorrectOptionIndex(null);
    setNumberOfOptions(0);
    form.setFieldsValue({ options: [] });
  };

  return (
    <Form form={form} onFinish={onFinish} name={surveyNumber} key={`form-${numberOfOptions}`}>
      {question.isQuiz && (
        <div style={{ marginBottom: '16px' }}>
          This is a quiz question. Please mark the correct answer.
        </div>
      )}
      <Space direction='vertical' style={{ width: '100%' }}>
        <Form.Item
          name='mainQuestion'
          rules={[
            { required: true, message: 'Please enter the question text' },
            { min: 5, message: 'Minimum 5 characters required' },
            {
              max: 500,
              message: 'Main question, maximum length is 500 characters',
            },
          ]}
        >
          <Input
            showCount
            maxLength={500}
            placeholder='Enter question text'
            onChange={handleQuestionTextChange}
          />
        </Form.Item>
        <Checkbox checked={useEmoji} onChange={handleUseEmojiChange}>
          Use Emojis <span style={{ fontSize: 20 }}>😎</span>
        </Checkbox>
        {options.map((option, optionIndex) => (
          <DraggableOption
            key={option.id}
            id={option.id}
            index={optionIndex}
            moveItem={moveOption}
            isQuiz={isQuiz}
            isCorrect={option.isCorrect}
            onCheckboxChange={(e) => setCorrectAnswer(optionIndex, e.target.checked)}
          >
            {useEmoji ? (
              <Form.Item
                name={['options', optionIndex, 'emojiType']}
                rules={[{ required: true, message: 'Emoji is required' }]}
              >
                <Select
                  value={option.emojiType}
                  placeholder={`Emoji ${String.fromCharCode(97 + optionIndex)}`}
                  allowClear
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.value?.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => updateOptionText(option.id, value, 'emojiType')}
                  style={{ width: 214, marginTop: 4 }}
                >
                  {Object.entries(Emojis).map((emoji, index) => (
                    <Option value={emoji[0]} key={index}>
                      {emoji[1]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name={['options', optionIndex, 'value']}
                rules={[
                  { required: true, message: 'Option text is required' },
                  { max: 20, message: 'Maximum 20 characters allowed' },
                ]}
              >
                <Input
                  showCount
                  maxLength={20}
                  onChange={(e) => updateOptionText(option.id, e.target.value, 'value')}
                  placeholder={`Option ${String.fromCharCode(97 + optionIndex)}`}
                  style={{ width: 214, marginBottom: -14 }}
                />
              </Form.Item>
            )}
            <Form.Item
              name={['options', optionIndex, 'description']}
              rules={[{ max: 60, message: 'Maximum 60 characters allowed' }]}
            >
              <Input
                placeholder='Description'
                showCount
                maxLength={60}
                onChange={(e) => updateOptionText(option.id, e.target.value, 'description')}
                style={{ width: 214, marginBottom: -14 }}
              />
            </Form.Item>
            <MinusCircleOutlined
              style={{ fontSize: 20, marginBottom: 8 }}
              onClick={() => removeOption(option.id)}
            />
          </DraggableOption>
        ))}
        {numberOfOptions < MAX_OPTIONS && (
          <Button type='dashed' onClick={addOption} icon={<PlusOutlined />} block>
            Add Option
          </Button>
        )}
        {numberOfOptions >= 2 && (
          <Row justify='space-between' gutter={[8, 12]} style={{ backgroundColor: '#f5f5f5' }}>
            <Col span={12}>
              <Button htmlType='submit' type='primary' block>
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={resetOptions} block>
                Reset
              </Button>
            </Col>
          </Row>
        )}
      </Space>
    </Form>
  );
};

export default SurveyFormBasic;
