import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import userReducer from './userReducer'
import setIsLoadingReducer from './setIsLoadingReducer'
import notificationReducer from './notificationReducer'
import errorsReducer from './errorsReducer'
import surveyReducer from './surveyReducer'
import dashboardReducer from './dashboardReducer'
import broadcastReducer from './broadcastReducer'
import contactsReducer from './contactsReducer'
import templateReducer from './templateReducer'
import pdfmeTemplateReducer from './pdfmeTemplateReducer'
import tagsFilterReducer from './tagsFilterReducer'
import lastSeenFilterReducer from './lastSeenFilterReducer'
import exchangeRateReducer from './exchangeRateReducer'
import integrationsReducer from './integrationsReducer'

import { RESET_REDUX_STATE } from '../actions/types'

const appReducer = combineReducers({
  user: userReducer,
  errors: errorsReducer,
  loading: setIsLoadingReducer,
  notify: notificationReducer,
  surveysData: surveyReducer,
  broadcastData: broadcastReducer,
  contactsData: contactsReducer,
  dashboard: dashboardReducer,
  tagsFilter: tagsFilterReducer,
  templatesData: templateReducer,
  lastSeenFilter: lastSeenFilterReducer,
  pdfmeTemplate: pdfmeTemplateReducer,
  exchangeRate: exchangeRateReducer,
  integrations: integrationsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === RESET_REDUX_STATE) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root')
    // storage.removeItem('persist:otherKey')

    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
